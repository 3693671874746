// import Vue from "vue";
// import App from "./App.vue";

// Vue.config.productionTip = false;

// new Vue({
//     render: (h) => h(App),
// }).$mount("#app");

// 2020-07-02 Manfred Wuits manfred@monochrom.at

//font aweasome svgs:
import {
    library,
    dom
} from "@fortawesome/fontawesome-svg-core";


import * as solidIcons from './icons/solid';
library.add(...Object.values(solidIcons));

import * as lightIcons from './icons/light';
library.add(...Object.values(lightIcons));

import * as brandIcons from './icons/brands';
library.add(...Object.values(brandIcons));


dom.watch();
